<template>
  <section class="library-form-section">
    <v-container>
      <!-- Form to add/edit library -->
      <v-form v-model="valid" ref="form" @submit.prevent="submitForm" class="library-form">        
          <v-col md="12" >
            <v-text-field
              v-model="library.name"
              label="Nom de librairie"
              outlined
              required
              class="form-field"
              :rules="[v => !!v || 'Nom est requis']"
            ></v-text-field>          
            <v-text-field
              v-model="library.numTel"
              label="Numéro de téléphone"
              outlined
              required
              class="form-field"         
            ></v-text-field>
            <v-text-field
              v-model="library.address"
              label="Addresse"
              outlined
              required
              class="form-field"
              :rules="[v => !!v || 'Adresse est requis']"
            ></v-text-field>
            <v-text-field
              v-model="library.postalCode"
              label="Code Postal"
              outlined
              required
              class="form-field"
              :rules="[v => !!v || 'Code Postal est requis']"
               @input="onCodePostalChange"
            ></v-text-field>
            <v-autocomplete
              v-model="library.city"
              :items="cities"
              item-value="label"  
              item-text="label"
              label="Ville"
              outlined
              @change="onCityChange"
              required
              :rules="[v => !!v || 'Ville est requis']"
            ></v-autocomplete>
          </v-col>
        <v-row v-if="library.name" class="upimg">
          <!-- Primary Image Upload -->
          <v-col cols="12" sm="6">
            <vue-upload-multiple-image
              :idUpload="imagesId"
              v-model="images"
              :data-images="images"
              name="images"
              drag-text="Please add an image!"
              browse-text="Browse image"
              primary-text="Primary image"
              mark-is-primary-text="Set default image"
              :min-image="1"
              :multiple="false"
              :loading="load"
              popup-text="Description default image"
              drop-text="Drag and drop"
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @images-updated="handleImagesUpdated"
            ></vue-upload-multiple-image>
            <v-progress-linear
              v-if="showLoading && uploadPercentage < 100"
              :value="uploadPercentage"
              height="15"
              color="primary"
              class="progress-bar"
            >
              <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
            </v-progress-linear>
          </v-col>
          <!-- Banner Image Upload -->
          <v-col cols="12" sm="6">
            <vue-upload-multiple-image
              :idUpload="imagesIdBanner"
              v-model="bannerImage"
              :data-images="bannerImage"
              name="bannerImage"
              drag-text="Please add a banner image!"
              browse-text="Browse banner image"
              primary-text="Banner image"
              :min-image="1"
              :multiple="false"
              :loading="load"
              popup-text="Description banner image"
              drop-text="Drag and drop"
              @upload-success="uploadBannerImageSuccess"
              @before-remove="beforeRemoveBanner"
              @images-updated="handleBannerImageUpdated"
            ></vue-upload-multiple-image>
            <v-progress-linear
              v-if="showLoadingBanner && uploadPercentageBanner < 100"
              :value="uploadPercentageBanner"
              height="15"
              color="primary"
              class="progress-bar"
            >
              <strong>{{ Math.ceil(uploadPercentageBanner) }}%</strong>
            </v-progress-linear>
          </v-col>
        </v-row>
      <v-row>
        <v-btn type="submit" color="primary" :loading="loading" class="submit-btn" >
            {{ isEditing ? 'Modifier' : 'Créer' }}
          </v-btn>
      </v-row>
      </v-form>
    </v-container>
  </section>
</template>
<script>
import axios from 'axios';
import VueUploadMultipleImage from "vue-upload-multiple-image";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import Constant from "@/utils/constants";
//import citiesData from '../../../cities.json';
import citiesData from '../../src/cities.json';
import { email } from 'vuelidate/lib/validators';
export default {
  components: { VueUploadMultipleImage },
  data() {
    return {
      libraries: [],
      cities: citiesData, // Use the imported data directly
    selectedCity: null, // For storing the selected city
    selectedCityDetails: null,
      library: {
        id: null,
        name: '',
        description: '',
        email: '',
        numTel: '',
        city: '',
        postalCode:'',
        latitude: '',
        longitude: '',
        address: '',
        fileId: null,
        fileBannerId: null,
        fileUrl: '',
        fileBannerUrl: '',
        ownerId: null,
      },
      images: [],
      bannerImage:[],
      bannerImageUpload:[],
      imagesUpload:[],
      load: false,
      uploadPercentage: null,
      uploadPercentageBanner: null,
      fakePercentage: null,
      showLoading: false,
      showLoadingBanner: false,
      loading: false,
      valid: false,
      imagesId: "1",
      imagesIdBanner: "0",
      imageIdBanner: null,
      imageId: null,
      search: '',
      noDataText: 'No categories found',
      isEditing: false,
      headers: [
        { text: 'Image', value: 'fileUrl' },
        { text: 'Nom', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Numéro de téléphone', value: 'numTel' },
        { text: 'Addresse', value: 'address' },
        { text: 'Ville', value: 'city' },
        { text: 'Naviguer', value: 'detail', sortable: false },
        { text: 'Modifier', value: 'edit', sortable: false },
        { text: 'Supprimer', value: 'delete', sortable: false },
      ],
      
    };
  },
  created() {  
    this.fetchLibraries();
    this.library.ownerId = localStorage.getItem('ownerId'); // Retrieve ownerId
    this.library.email = localStorage.getItem('userEmail'); //Retrive userEmail
  },
  methods: {
    onCityChange(selectedCityName) {
    // Find the selected city object by its name
    const selectedCity = this.cities.find(city => city.label === selectedCityName);  
    if (selectedCity) {
      this.selectedCityDetails = {
        label: selectedCity.label,
        latitude: selectedCity.latitude,
        longitude: selectedCity.longitude,
        codepostal: selectedCity.zip_code 
      };
      console.log('Selected city:', selectedCity);
      // Update latitude, longitude and postal code in the library object
      this.library.latitude = selectedCity.latitude;
      this.library.longitude = selectedCity.longitude;
      this.library.postalCode = selectedCity.zip_code;
    }
  },
  onCodePostalChange() {
    const matchingCity = this.cities.find(postalCode => postalCode.zip_code === this.library.postalCode);
    if (matchingCity) {
      this.library.city = matchingCity.label;
      this.library.latitude = matchingCity.latitude;
      this.library.longitude = matchingCity.longitude;
    } else {
      // If no zip code matches, you can clear or reset the city
      this.library.city = '';
      this.library.latitude = '';
      this.library.longitude = '';
    }
  },
    viewLibraryDetails(libraryId) {

        this.$router.push({ name: 'LibraryBooks', params: { id: libraryId } });
      },
    async fetchLibraries() {
      try {

        const response = await axios.get(Constant.QORANI +'library/library/getall');
        this.libraries = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des bibliothèques:', error);
      }
    },
    
    async submitForm() {
      // Trigger validation
  this.$refs.form.validate();

// Check if form is valid
if (!this.valid) {
  console.log("La validation du formulaire a échoué.");
  return;
}
  try {
    this.loading = true;
    // Validate form data before submission
    let response;
       
      // Add new library
      console.log(this.library);
      console.log("&&&&&&");
     
      const userData = {
        name:this.library.name,
        email:this.library.email,
        numTel:this.library.numTel,
        city:this.library.city,
        postalCode:this.library.postalCode,
        latitude:this.library.latitude,
        longitude:this.library.longitude,
        address:this.library.address,
        fileId:this.library.fileId,
        fileBannerId:this.library.fileBannerId,
        ownerId : this.library.ownerId
        };
      response = await fetch(Constant.QORANI + "library/library/add", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        });
        console.log(userData);
        console.log("&&&&&&");
    
    this.$refs.form.reset();
     // Refresh the list of libraries
     await this.fetchLibraries();

// Navigate back to the previous page
this.$router.go(-1); // Go back to the previous page
  } catch (error) {
    console.error('Erreur lors de l envoi du formulaire :', error.response ? error.response.data : error);
    alert(`Échec de la soumission: ${error.response?.data?.message || error.message}`);
  } finally {
    this.loading = false;
  }
},
isFormValid() {
  // Check that all required fields are filled correctly
  return (
    this.library.name &&
    (!this.user.email || this.user.email) &&
    (!this.user.email || this.library.numTel) &&
    this.library.latitude &&
    this.library.longitude &&
    this.library.city &&
    this.library.address &&
    this.library.postalCode
  );
},
resetForm() {
  this.library = {
    id: null,
    name: '',
    description: '',
    email: '',
    numTel: '',
    city:null,
    postalCode:null,
    latitude: '',
    longitude: '',
    address: '',
    fileId: null,
    fileBannerId: null,
    fileUrl: '',
    fileBannerUrl: '',
    ownerId: localStorage.getItem('ownerId'),
  }; // Reset form fields
  this.userEmails='';
},
    handleImagesUpdated(images) {
      const uploadedImage = images[0];
      console.log("Image téléchargée :", uploadedImage);
    },
    async uploadImageSuccess(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}_${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${imageName}_${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`libraries/${newImageName}`);
      try {
        this.showLoading = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentage = progress;
          },
          (error) => {
            alert(`Erreur lors du téléchargement de l'image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            
            console.log("URL du fichier avant de définir :", this.library.fileUrl);
              this.library.fileUrl = url;
          console.log("URL du fichier après réglage :", this.library.fileUrl);

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ fileName: imageName, fileUrl: url }),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.library.fileId = responseData.id;
                this.library.fileUrl = responseData.url;
                this.showLoading = false;
              } else {
                this.showLoading = false;
                console.error(
                  "Erreur lors du téléchargement de l'image. État de réponse du serveur :",
                  response.status
                );
              }
            } catch (error) {
              console.error("Erreur lors du téléchargement de l'image :", error);
            }
            this.showLoading = false;
          }
        );
      } catch (error) {
        this.showLoading = false;
        alert(`Erreur lors du téléchargement de l'image ${error.message}`);
      }
    },
    beforeRemove(index, done, fileList) {
      this.showLoading = false;
      this.uploadPercentage = 0;
      const r = confirm("Remove image");
      if (r === true) {
        this.library.fileUrl = "";
        this.library.fileId = "";
        done();
      }
    },
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], { type: "image/jpeg" });

      return new File([blob], element.name);
    },
    //////////////////////////////////////////////////////////////////
    handleBannerImageUpdated(bannerImage) {
      const uploadedImage = bannerImage[0];
      console.log("Uploaded image:", uploadedImage);
    },
    async uploadBannerImageSuccess(formData, index, fileList) {
      const imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
      const contentType = `image/${ext}`;
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}_${(
        currentDateTime.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getDate()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getHours()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}_${currentDateTime
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const newImageName = `${imageName}_${formattedDateTime}`;
      const file = this.convertImagePathToFile(fileList[index]);

      const storageRef = firebase
        .app()
        .storage()
        .ref(`libraries/${newImageName}`);
      try {
        this.showLoading = true;
        const metadata = {
          contentType: contentType,
        };
        const uploadTask = storageRef.put(file, metadata);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadPercentage = progress;
          },
          (error) => {
            alert(`Erreur lors du téléchargement de l'image ${error.message}`);
          },
          async () => {
            const url = await storageRef.getDownloadURL();
            
            this.library.fileBannerUrl = url;

            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ fileName: imageName, fileUrl: url }),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.library.fileBannerId = responseData.id;
                this.library.fileBannerUrl = responseData.url;
                this.showLoadingBanner = false;
              } else {
                this.showLoadingBanner = false;
                console.error(
                  "Erreur lors du téléchargement de l'image. État de réponse du serveur :",
                  response.status
                );
              }
            } catch (error) {
              console.error("Erreur lors du téléchargement de l'image :", error);
            }
            this.showLoadingBanner = false;
          }
        );
      } catch (error) {
        this.showLoadingBanner = false;
        alert(`Erreur lors du téléchargement de l'image ${error.message}`);
      }
    },
    beforeRemoveBanner(index, done, fileList) {
      this.showLoadingBanner = false;
      this.uploadPercentageBanner = 0;
      const r = confirm("Remove image");
      if (r === true) {
        this.library.fileBannerUrl = "";
        this.library.fileBannerId = "";
        done();
      }
    },
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], { type: "image/jpeg" });

      return new File([blob], element.name);
    },
  }
};
</script>
<style scoped>
.library-form-section {
  padding: 20px;
  background-color: #f4f5f7; /* Light gray background for a modern look */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 70px;
}
.library-form {
  padding: 24px;
  background-color: #ffffff; /* White background for clean appearance */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 70%;
  margin-left: 150px;
}
.form-field {
  margin-bottom: 16px; /* Reduced spacing for a more compact form */
}
.submit-btn {
  margin-top: 20px;
  width: 200px;
  border-radius: 24px; /* Rounded button for modern feel */
  margin-left: 280px;
}
.progress-bar {
  margin-top: 10px;
}
.search-field {
  max-width: 350px; /* Slightly wider search field */
}
.library-data-table {
  margin-top: 20px;
  border-radius: 12px;
  overflow: hidden; /* Ensures rounded corners for the table */
}
.v-divider {
  margin: 20px 0;
  border-top: 1px solid #e0e0e0; /* Subtle divider line */
}
.v-data-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Modern shadow for table */
}
.v-data-table .v-data-table__wrapper {
  border-radius: 12px;
}
.v-data-table thead th {
  background-color: #f4f5f7; /* Light background for table headers */
  color: #333; /* Darker text for contrast */
  font-weight: 500; /* Slightly bold headers */
  border-bottom: 2px solid #e0e0e0; /* Modern border for headers */
}
.v-data-table tbody tr {
  transition: background-color 0.3s; /* Smooth transition for row hover */
}
.v-data-table tbody tr:hover {
  background-color: #f1f3f4; /* Subtle row hover effect */
}
.v-icon {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}
.icon-edit {
  color: #1976d2; /* Edit icon color */
}
.icon-delete {
  color: #d32f2f; /* Delete icon color */
}
.icon-view {
  color: #388e3c; /* View icon color */
}
.icon-edit:hover {
  color: #1565c0; /* Darker blue on hover */
  transform: scale(1.2); /* Slightly larger on hover */
}
.icon-delete:hover {
  color: #b71c1c; /* Darker red on hover */
  transform: scale(1.2); /* Slightly larger on hover */
}
.icon-view:hover {
  color: #2c6b2f; /* Darker green on hover */
  transform: scale(1.2); /* Slightly larger on hover */
}
.upimg{
  margin-left: 100px;
}

</style>


